<template>
  <div>
    <NavTitle class="mb16" title="顾问分配" :showBack="false">
      <template #right>
        <div class="flex flex-end items-center">
          <el-button type="primary" @click="$refs.addPopupRef.open()">新建+</el-button>
          <div class="input-row mr16 ml16">
            <el-input class="input" placeholder="请输入商户ID/手机号/名称" clearable v-model="form.keyword" @change="getAllocationList"></el-input>
            <el-button type="primary" icon="el-icon-search"  @click="getAllocationList"></el-button>
          </div>
          <el-date-picker
            v-model="form.screenDate"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="datetimerange"
            range-separator="~"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            @change="getAllocationList">
          </el-date-picker>
          <!-- <el-button type="primary" size="medium">
            <span class="flex items-center">
              <span>下载</span>
              <img class="icon-download" src="@/assets/images/download-w.png">
            </span>
          </el-button> -->
        </div>
      </template>
    </NavTitle>
    <div class="table-panel">
      <el-table :data="allocationList" style="width: 100%" 
        :header-cell-style="{background: '#f0f1f3', color: '#333'}"
        v-loading="tableLoading">
        <el-table-column
          prop="merchantName"
          label="商户名称">
        </el-table-column>
        <el-table-column
          prop="mid"
          label="mid">
        </el-table-column>
        <el-table-column
          prop="mobile"
          label="注册账号">
        </el-table-column>
        <el-table-column
          prop="category"
          label="招聘类目">
        </el-table-column>
        <el-table-column
          prop="regTime"
          label="注册日期"
          width="160"
          sortable>
        </el-table-column>
        <el-table-column
          prop="consultantName"
          label="商务顾问"
          :key="Math.random()">
          <template slot="header" v-if="counselorList && counselorList.length > 0">
            <el-dropdown @command="counselorCommand">
              <span style="color: #333333;" :class="{'theme-text': form.bid}">
                商务顾问<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item :command="item.bid" v-for="(item, index) in counselorList" :key="index">
                  <div :class="['flex', 'items-center', form.bid === item.bid ? 'theme-text':'']">
                    <img v-if="form.bid === item.bid" class="icon-selected" src="@/assets/images/icon-gouxuan.png">
                    <span>{{item.name}}</span>
                  </div>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
        <el-table-column
          prop="statusDesc"
          label="签约状态">
          <template slot="header">
            <el-dropdown @command="statusCommand">
              <span style="color: #333333;" :class="{'theme-text': form.status || form.status === 0}">
                签约状态<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item :command="item.type" v-for="(item, index) in statusTypes" :key="index">
                  <div :class="['flex', 'items-center', form.status === item.type ? 'theme-text':'']">
                    <img v-if="form.status === item.type" class="icon-selected" src="@/assets/images/icon-gouxuan.png">
                    <span>{{item.text}}</span>
                  </div>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
        <el-table-column
          prop="appChannel"
          label="应用/渠道">
        </el-table-column>
        <el-table-column
          prop="source"
          label="来源">
        </el-table-column>
        <el-table-column
          prop="contactName"
          label="联系人">
        </el-table-column>
        <el-table-column
          prop="contactInfo"
          label="联系方式">
        </el-table-column>
        <el-table-column
          prop="memo"
          label="备注">
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <div class="flex justify-center mt16">
        <el-pagination
          layout="total, prev, pager, next, sizes"
          :total="page.total"
          :page-size="page.size"
          :page-sizes="[5, 10, 15, 20]"
          @current-change="pageCurrentChange"
          @size-change="pageSizeChange">
        </el-pagination>
      </div>
    </div>
    
    <AddAllocationPopup ref="addPopupRef" @update="getAllocationList()"></AddAllocationPopup>
  </div>
</template>

<script>
import NavTitle from '@/components/NavTitle'
import AddAllocationPopup from './components/AddAllocationPopup.vue'
import { accessibleConsultantsApi, queryConsultantAllocatesApi } from '@/api/admin.js'
export default {
  name: 'Adviser',
  components: {
    NavTitle,
    AddAllocationPopup
  },
  data() {
    return {
      statusTypes: [
        {text: '未合作', type:0},
        {text: '未签约', type:1},
        {text: '已签约', type:2},
      ],
      page: {
        current: 0,
        size: 10,
        total: 0
      },
      allocationList: [],
      counselorList: [],
      form: {
        bid: '',
        keyword: '',
        status: '',
        screenDate: [],
      },
      tableLoading: false
    }
  },
  created() {
    this.getAllocationList()
    this.getCounselorList()
  },
  methods: {
    getAllocationList() {
      const formData = {
        pageSize: this.page.size,
        startPage: this.page.current,
        keyword: this.form.keyword,
        status: this.form.status,
        bid: this.form.bid
      }
      if(this.form.screenDate?.length > 0) {
        Object.assign(formData, {
          startTime: this.form.screenDate[0],
          endTime: this.form.screenDate[1]
        })
      }
      console.log('formdata', formData)
      this.tableLoading = true
      queryConsultantAllocatesApi(formData).then(res => {
        console.log('---顾问分配列表---', res)
        this.tableLoading = false
        if(res.data) {
          this.allocationList = res.data.consultantAllocates || []
          this.page.total = res.data.totalNum || 0
        }
        
      }).catch(err => {
        this.tableLoading = false
        this.$tips({message: err, type:'error'})
      })
    },
    getCounselorList() {
      accessibleConsultantsApi().then(res => {
        console.log('全部商务顾问', res)
        this.counselorList = res.data?.consultantList || []
      }).catch(err => {
        this.$tips({message: err, type:'error'})
      })
    },
    counselorCommand(command) {
      console.log(command)
      this.form.bid = this.form.bid === command ? '' : command
      this.getAllocationList()
    },
    statusCommand(command) {
      console.log(command)
      this.form.status = this.form.status === command ? '' : command
      this.getAllocationList()
    },
    pageCurrentChange(e) {
      console.log('pageCurrentChange', e)
      this.page.current = e - 1
      this.getAllocationList()
    },
    pageSizeChange(size) {
      this.page.size = size
      this.getAllocationList()
    }
  }
}
</script>

<style lang="scss" scoped>
.input-row {
  width: 300px;
  display: flex;
  align-items: center;
}
.icon-download {
  width: 18px;
  height: 18px;
  margin-left: 4px;
}
.icon-selected {
  width: 20px;
  height: 20px;
  margin-right: 8px;
  margin-left: -4px;
}
.table-panel {
  background: #fff;
  padding: 10px;
  margin: 16px 20px 0;
}
</style>